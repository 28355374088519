.scroll-container {
   position: relative;
   width: 100%;
   font-family: 'APK';
   display: flex;
   flex-direction: column; 
   height: 100vh;
   align-items: center; 
   justify-content: center; 
}

.scroll-title-container {
   display: flex;
   font-family: 'Monument';
   flex-direction: row;
   justify-content: space-between;
   width: 100%;
}


.scroll-title span {
   font-size: 12px;
}

.scroll-items {
   width: 80%; 
   margin: auto; 
}   

.scroll-content {
   display: flex;
   flex-direction: row;
   gap: 30px; 
   width: 100%; 
   margin-left: 2%;
}

section {
   padding: 20px;
}

section h3 {
   display: flex;
   justify-content: flex-start;
   font-size: 1vw;
   margin-bottom: -10px; 
   width: 100%;
   color: black;

}

section ul {
   list-style-type: none; 
   padding: 0; 
}
  
section li {
   font-size: 1.2vw;
   margin-bottom: 8px; 
   font-family: 'montreal';
   color: gray;

}

.scroll-title {
   width: 100%;
   display: flex;
   font-family: 'montreal';
   justify-content: flex-start;

}



.scroll-title {
   font-size: 6vh;
}

.scroll-title span {
   font-family: 'APK';
   font-size: 8px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
   .scroll-container {
       height: auto;
   
   }

   .scroll-title h2 {
       font-size: 10vw; 

   }

   .scroll-content {
       display: grid;  
       grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
       width: 95%;
   
   }

   section h3 {
      display: flex;
      justify-content: flex-start;
      font-size: 2vw;
      margin-bottom: -10px; 
      width: 100%;
      color: black;
   
   }

.scroll-title {
   font-size: 32px;
   margin-right: 0%;
}

   .scroll-content-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
       gap: -90px;
       width: 100%;
       height: 150px;
       margin-left: 0px;
   
   }

   section ul {
      display: flex;
      flex-direction: column;
      gap: -50px;
      margin-left: 50%;
   }

   section li {
       font-size: 2vw; 
   }
}

@media (max-width: 480px) {
   .scroll-content {
       display: grid;  
       grid-template-columns: repeat(2, 1fr); /* Two columns for smaller screens */
       width: 100%;
       gap: 10px;
   }

   .scroll-content-container {
       width: 100%;
   }

   section ul {
      display: flex;
      flex-direction: column;
      gap: -80px;
   }

   section li {
       font-size: 3vw; 
   }

}


  

                        