@font-face {
    font-family: 'APK';
    src: url('../../../assets/fonts/APKArchivr21_Regular.otf') format('truetype');
}

@font-face {
    font-family: 'NeueHaas';
    src: url('../../../assets/fonts/NeueHaasGrotText-75Bold-Trial.otf') format('opentype');
}

@font-face {
    font-family: 'montreal-book';
    src: url('../../../assets/fonts/PPNeueMontrealMono-Book.otf') format('opentype');
}

/* Base styles for mobile */
.about-me-section {
    padding: 20px;
    font-family: 'APK';
    margin-bottom: 10%;
}

/* Title Section */
.about-title {
    font-size: 4vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'montreal';
    margin-bottom: 20px;
}

.about-title span {
    font-size: 10px;
    margin-left: 5px;
    margin-bottom: 5%;
    font-family: 'APK';
    color: #666;
}

/* Card Container */
.card-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Card */
.card {
    border-bottom: 1px solid gainsboro;
    border-radius: 5px;
    overflow: hidden;
}

.card-header {
    display: flex;
    font-family: 'montrealbook';
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    cursor: pointer;
    font-size: 1.5vh;
}

.custom-font-arrow {
    font-size: 30px;
    color: black;
    font-family: 'APK';
}

.card-content {
    display: none;
    padding: 10px 0;
    margin-top: -25px;
}

/* List Styling for mobile */
.list {
    padding: 0;
    margin: 0;
    list-style: none;
    font-family: 'montreal';
    margin-bottom: 8%;
}



.list li {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left for mobile */
    padding: 10px 0;
    font-size: 14px;
    font-family: 'montreal';
    color: gray;
    padding-left: 4%;
    position: relative; /* Required for the bullet to be positioned correctly */
}


/* Expanded Card Content */
.card.expanded .card-content {
    display: block;
}

.dates {
    font-size: 10px;
}

.role {
    color: black;
}

/* Toggle Button */
.toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.toggle-button:focus {
    outline: none;
}

/* Media Queries for Tablet and Desktop Sizes */

/* Tablet Sizes (min-width: 768px) */
@media (min-width: 768px) {
    .about-title {
        font-size: 6vh;
    }

    .custom-font-arrow {
        font-size: 50px;
    }

    .list li {
        padding: 15px 0;
        font-size: 16px;
    }
}

/* Desktop Sizes (min-width: 1024px) */
@media (min-width: 1024px) {
    .about-title {
        font-size: 6vh;
    }

    .custom-font-arrow {
        font-size: 65px;
    }


    .list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .list li {
        display: flex;
        flex-direction: row; /* Items will be spread across horizontally */
        justify-content: center; /* Spread the items across the row */
        align-items: center;
        padding: 20px 0;
        width: 90%;
        border-top: 1px solid gainsboro;

    }

    /* Role aligned to the left, company and dates on the right */
    .role {
        flex: 1;
        text-align: left; /* Role stays on the left */
        font-size: 20px;
    }

    .right-section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        text-align: right;
    }

    .company {
        font-size: 20px;
        margin-right: 5px;
    }

}

