/* Full width on larger screens */
.low-poly-container {
    width: 30vw;
    height: 50vh;
    top: 28vh;
    left: 70vw;
    position: absolute;
    
  }
  
  /* For tablets (768px and smaller) */
  @media (max-width: 1200px) {
    .low-poly-container {
        top: 3vh;
        bottom: 4vh;
        left: 2vw;
      height: 10vh;
      width: 20vw;
      display: none;

    }
  }
  
  /* For mobile phones (480px and smaller) */
  @media (max-width: 480px) {
    .low-poly-container {
    top: 2vh;
      height: 60vh;
      display: none;
    }
  }
  