/* Mobile-first styles */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'montreal';
margin-top: 15%;
  padding: 10px;
}

.contact-container-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  
}

.contact-container-title span {
  font-size: 14px;
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.form-group input,
.form-group textarea {
  padding: 5%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid gainsboro;
  font-size: 25px;
  font-family: 'montreal';
  color: black;
  width: 100%;
  transition: border-color 0.3s ease; /* Smooth transition for the border color change */
}

.form-group input:focus,
.form-group textarea:focus {
  border-bottom: 1px solid #800080; /* Change border-bottom to purple when the element is focused */
  outline: none; /* Optional: removes the default focus outline to rely only on the border color change */
}




.form-group {
  display: flex;
  flex-direction: row;
  font-size: 22px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea {
  padding: 5%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid gainsboro;
  font-size: 15px;
  font-family: 'montreal';
  color: black;
  width: 100%;
}

.social-section {
  width: 100%;
  order: 2; /* Keeps social section below the form on mobile */
}

.globe-section {
  display: none; /* Globe is not displayed on mobile and tablet */
  margin-bottom: 20px;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  font-family: 'montreal';
  font-size: 15px;
  cursor: pointer;
  border-radius: 20px;
  color: black;
  background-color: transparent;
}

.custom-font-arrow-2 {
  font-family: 'APK';
  font-size: 20px;
  color: black;
}

.social-section-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10%;
  width: 10%;
}

.social-section-col {
  margin-bottom: 20px;
  font-size: 10px;
}

.social-section-label {
  margin-bottom: -5px;

}


.social-section-col p{
  margin-top: 5px;
}

a {
  text-decoration: none;
  color: inherit
}

@media (min-width: 1024px) { /* Typically 992px is used for desktops but adjusted to 1024px */
  .contact-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch; /* Ensures that all direct children align their heights */
  }

  .globe-section {
    display: block; /* Makes the globe visible on desktops only */
    flex: 1; /* Allows the globe section to take half of the container's width */
    order: 1; /* Ensures globe section is on the left */
  }

  .form-section {
    flex: 1; /* Allows the form to take about half of the container's width */
    order: 2; /* Ensures form section is on the right */
  }

  .social-section {
    flex: 1; /* Restores normal order for desktop, aligning side by side with the globe */
    margin-right: 5%;
    margin-bottom: 5%;
  }

  .social-section-row {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .social-section-col {
    flex: 1; /* Allows social links to fill the space evenly */
    padding: 0 10px; /* Adjust padding to bring columns closer together */
  }
}

  