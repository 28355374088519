/* NavBar.css */

@font-face {
  font-family: 'Tephra';
  src: url('../../assets/fonts/Tephra50_Trial_Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'Tephra bold';
  src: url('../../assets/fonts/Tephra0_Trial_Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'PPSupply';
  src: url('../../assets/fonts/PPSupplyMono-Ultralight.otf') format('opentype');
}

@font-face {
  font-family: 'Trod';
  src: url('../../assets/fonts/TROD-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'APK';
  src: url('../../assets/fonts/APKArchivr21_Regular.otf') format('opentype');
}


:root {
  --background-color-light: #FFFF;
  --text-color-light:#131313;
  --background-color-dark: #131313;
  --text-color-dark: #FFFF;
  --border-bottom-dark: #FFFF;
  --border-bottom-light: #131313;
}

body.light-mode {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

body.dark-mode {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.themeButton {
  height: 65px;
  padding-left: 6%;
  padding-right: 6%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.toggle-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40px; /* Adjust based on design */
  cursor: pointer;
}

.square {
  font-family: 'APK', sans-serif; /* Use your custom font here */
  font-size: 10px; /* Adjust as necessary */
  margin: 0 5px;
  transition: color 0.3s ease-in-out;
}

.light-mode-square {
  color: orangered; /* Light mode square color */
}
.square.active {
  transform: scale(1.2); /* Slightly enlarge the active square for visual feedback */
  color: #131313;
}


.themeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.nav-link {
  color: grey;
}


.nav-link.active {
  color: black; 
  font-weight: bold;
}

.nav-link.active::before, .nav-link.active::after {
  content: " ( "; 
}

.nav-link.active::after {
  content: " ) "; 
}


.logo.light-mode {
  color: black; 
}

.logo.dark-mode {
  color: white;   
}


.body .navbar.light-mode {
  border-bottom: 1px solid gray;
}
 
.body.nav-item.light-mode {
  border-bottom: 1px solid black;
}

.menu-toggle-container {
  font-family: 'APK';
  color: black;
}


.navbar {
  display: flex;
  position: sticky;
  justify-content: space-between;
  align-items: center; 
  width: 100%;
  top: 0;
  z-index: 2000;
  padding-bottom: 1%;
  backdrop-filter: blur(10px);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2000;
  font-size: 25px;
  text-decoration: none;
}

.logo img {
  width: 75px;
  height: 100px;
}

.logo h2 {
  margin-bottom: 5px;
}

.logo-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-left: 1%;
  gap: 30px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
}


.reg-mark {
  font-size: 1.2em;
  font-family: 'montreal';
  right: 5px;
  top: -10px;
  z-index: 2001;
  position: absolute;
}

.navbar-nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end; 
  margin: 0;
  padding: 0;
  width: 100%;
}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'APK';
  height: 65px;
  width: 90px;
  font-size: 14px;
  cursor: pointer;
  border-left: none;
}  

.nav-link {
  text-decoration: none;
  padding: 0.5rem 2rem;
  transition: color 0.3s;
}


.title-container {
  font-family: 'PPSupply';
  color: grey;
  font-size: 10px;
  width: 100%;
}

.menu-toggle {
  display: none;
  color: black;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  position: fixed;
  z-index: 201; 
}

canvas {
  display: none

}

.navbar-nav {
  display: flex; 
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item p{
  display: none;
}


.navbar-nav .nav-item {
  margin-right: 10px; 
}

.navbar-nav .nav-item .nav-link {
  text-decoration: none;
  display: flex;
}

.blur-section {
  filter: blur(0px);
}






@media screen and (max-width: 1024px) {
  .menu-toggle {
    position: relative;
    background-color: transparent;  /* Ensures button background doesn't obscure corners */ /* Provide sufficient padding */
    cursor: pointer;
    display: inline-block;  /* Align button properly */
    height: 65px;
    font-size: 12px;
    width: 100%;
    font-family: "APK";

}

.menu-toggle-container {
  display: flex;
  width: 100px;
  justify-content: center;
}

.menu-toggle.open {
  color: white;
}

.logo-container {
  margin-right: 12%;
  display: flex;
  gap: 5px;
}

.logo {
  margin-left: 0%;

}

.logo img {
  width: 60px;
}

.reg-mark {
  right: -10px;
  top: -4px;

}


  body.dark-mode .menu-toggle {
    color: white;

  }

.navbar-nav {
  position: fixed;
  top: 0;
  left: -3.7%;
  right: 10%;
  width: 105vw;
  height: 100vh; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s, transform 0.3s ease 0.3s; /* Delay transform to allow glitch effect to show */
  overflow: hidden; /* Prevents content from spilling out when height is 0 */
  z-index: 200;
  visibility: hidden;
  
}

.navbar-nav.open {  
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
  transition: opacity 0.3s ease, visibility 0s linear 0s, transform 0.3s ease 0s; /* No delay when opening */
}


  body.dark-mode .navbar-nav.opn ul {
    border-left: none;
  }

  .navbar-nav ul {
    display: none;
  }

  canvas{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -100;

  }
  .toggle-switch {
    margin-left: -40px;
  }

  .nav-link.active::before, .nav-link.active::after {
    content: "  "; 
  }
  
  .nav-link.active::after {
    content: "  "; 
  }
  

  .navbar-nav.open ul {
    width: 50%;
    margin: 0;
    padding: 0;
    margin-left: 40%;
    display: flex;
    border-left: none;
    flex-direction: column;
    align-items: center;

  }

  .nav-item p{
    display: block;
    color: white;
    font-family: 'APK';
    margin-left: -25px;
    font-size: 8px;
    margin-bottom: 30px;
  }


  .navbar-nav ul li {
    width: 100%;
    font-family: 'montreal';
    height: 10%;
    display: flex;
    gap: 0px;
    margin-bottom: 15%;
    text-align: left !important;
    justify-content: flex-end;
    align-items: center;
    border-left: none;

  }



  body.dark-mode .navbar-nav ul li {
    border-left: none;
  }

  body.light-mode .navbar-nav ul li {
    border-left: none;
  }


  .nav-link {
    color: white;
    font-size: 35px;
    cursor: pointer;
    padding-left: 0 !important; /* Remove any left padding if existing */
    text-align: left !important; /* Ensures text alignment to the left */
  }
  .nav-link.active{
    color: white;
  }
  
}











