/* index.css */
/* WorkMenu.css */

.work-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'APK';
  padding: 10px;
  margin-bottom: 15%;
}

.work-menu-title {
  display: flex;
  justify-content: flex-start;
  font-family: 'montreal';
  font-size: 6vh;
  margin-bottom: 20px;
}

.work-menu-title span {
  font-size: 8px;
  font-family: 'APK';
}

.project-row {
  cursor: pointer;
  padding: 15px;
  border-bottom: 1px solid gainsboro;
}

.project-row:hover {
  background-color: orangered;
  color: white;
}

.project-row:hover .project-description {
  color: white;
}

.project-detail {
  display: flex;
  flex-direction: column; /* Stacking items vertically on small screens */
  align-items: flex-start; /* Aligning items to the start */
  width: 100%;
}

.project-title {
  display: flex;
  align-items: center;
  font-family: 'montreal';
}

.project-name-row {
  font-size: 3vw;
  display: flex;
  flex-direction: column; /* Name and year stacked on mobile */
}

.project-year {
  font-size: 2vw;
  font-family: 'APK';
  margin-top: -5%;
}

.project-link-section {
  text-align: left;
  display: flex;
  flex-direction: column; /* Description below year on mobile */
  width: 100%;
  margin-top: 5%;
}

.project-description {
  color: #666;
  font-family: 'montreal';
  font-size: 2vw;
}

@media (min-width: 768px) { /* Adjusts for tablets and desktops */

  .project-title {
    width: 100%;
  }

  .project-name {
    width: 20%;
  }

  .project-name-row {
    flex-direction: row; /* Horizontal layout for name and year */
    align-items: center; /* Aligns content vertically */
    justify-content: space-between;
    width: 100%;
  }

  .project-year {
    font-size: 1.5vh;
    margin-top: 0%;
    display: flex;
    width: 10%;
    align-items: flex-start;
    justify-content: flex-start;

  }

  .project-description {
    font-size: 1.5vw;
    text-align: right;
    width: 18%;
  }

  .project-name-row {
    font-size: 1.5vw;
  }
  
  

}








