/* index.css */



@font-face {
  font-family: 'Monument';
  src: url('../../../assets/fonts/PPMonumentExtended-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'montreal';
  src: url('../../../assets/fonts/PPNeueMontreal-Book.otf') format('opentype');
}

@font-face {
  font-family: 'montrealbook';
  src: url('../../../assets/fonts/PPNeueMontreal-Book.otf') format('opentype');
}


@font-face {
  font-family: 'PPSupply-bold';
  src: url('../../../assets/fonts/PPSupplyMono-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'PPSupply';
  src: url('../../../assets/fonts/PPSupplyMono-Ultralight.otf') format('opentype');
}

header {
  display: grid;
  grid-template-rows: auto auto auto; 
  gap: 10px;
  margin-top: 1%;
  padding: 1%;
  margin-bottom: 10%;
  color: #333;

 
  grid-template-areas:
    "topContainer"
    "middleSection"
    "bottomContainer";
}

.nameInfo h1 {
  font-family: 'montreal';
  font-size: 10.2vh;
  margin-bottom: -8%;
  margin-top: 3%;
  font-size: 11vw;
}



.mouse-info {
  font-family: 'APK';
  font-size: 0.7rem;
}





/* Ensure the ul and li are aligned with the h4 */


.nameInfo h1 span {
  font-family: 'APK';
}

.bottom-info ul {
  list-style: none; /* Remove default bullet points */
  padding: 0;       /* Remove default padding on ul */
  margin: 0;        /* Remove default margin on ul */

}

.bottom-info li {
  padding-left: 0; /* Ensure no padding on list items */
  margin-left: 0;  /* Align with the h4 tag */
  font-family: 'montreal'; /* Optional, applies font-family to the list */
  font-size: 1.5vw;
  color: gray;
  font-size: 1.5vh;
}

.nav-item {
  border-left: 1px solid gainsboro;
}



.description {
  position: relative;
  font-family: 'montreal';
  font-size: 2vw;
  font-size: 1.4vh;
  color: gray;
  margin-top: 20%;
  width: 50%;
  margin-left: 50%;
}

/* Add a square before the text */
.description::before {
  content: "■"; /* Unicode square character */
  position: absolute;
  left: -20px; /* Position it to the left of the text */
  top: 3px; /* Align it with the top of the paragraph */
  font-size: 10px; /* Adjust the size of the square */
  color: black; /* Adjust the color of the square */
  font-family: 'APK'; /* Ensure it uses your custom font */
}

.bottom-info h4 {
  font-size: 1vh;
  font-family: 'APK';
}


.topContainer {
  margin-top: 10%;
  grid-area: topContainer;
  height: 10vh;
}

.middleSection {
  grid-area: middleSection;
  height: 60vh;

}


.bottomContainer {
  grid-area: bottomContainer;
  display: flex;
  align-items: flex-start;
  border-top: 1px solid gainsboro;
  width: 100%;
  justify-content: space-between;
}


.timeLocationInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 2%;
  height: 10%;
  padding-bottom: 2%;
  font-family: 'APK';
}

.timeInfo, .currentLocation {
  font-family: 'APK';
  font-size: 7px;
  
}

.timeInfo h4, .currentLocation h4 {
  margin: 0;
  font-family: 'APK';
  font-size: 7px;
  margin-top: 2px;
  color: #333;
}

.timeInfo {
  text-align: left;
}

.time-label {
  display: flex;
  font-family: 'APK';
  height: 5px;
  margin-bottom: 5%;
  width: 20%;
  padding-bottom: 5%;
  justify-content: flex-start;
  align-items: center;
}

.loc-label {

  display: flex;
  font-family: 'APK';
  height: 5px;
  margin-bottom: 5%;
  width: 45%;
  padding-bottom: 5%;
  justify-content: flex-start;
  align-items: center;
}

.currentLocation {
  text-align: right;
}


.bottom-info {
  display: flex;
  justify-content: center; 
  align-items: flex-start;    
  gap: 20px;   
        
}

.bottom-info div {
  display: flex;
  flex-direction: column;  
  justify-content: flex-start;
  align-items: flex-start;
}


.mouse-info {
  display: none;
}





@media (min-width: 768px) {
  header {
    grid-template-columns: 1fr 1fr; 
    grid-template-areas:
      "topContainer topContainer"
      "middleSection middleSection"
      "bottomContainer bottomContainer";
  }

  .mouse-info {
    display: block;
  }

  .description {
    font-family: 'montreal';
    font-size: 2vw;
    font-size: 1.5vh;
    color: lightgray;
    width: 30%;
    margin-top: 5%;
    margin-left: 52%;

  }
  .topContainer {
    height: 10vh;
  }
  
  .middleSection {
    height: 60vh;
  
  }
}


@media (min-width: 1024px) {
  header {
    grid-template-columns: 1fr 1fr; 
    grid-template-areas:
      "topContainer topContainer"
      "middleSection middleSection"
      "bottomContainer bottomContainer"; 
  }

  .mouse-info {
    display: block;
  }

  .nameInfo h1 {
    margin-top: -2%;
    font-size: 5vh;
    margin-left: -1%;
    margin-bottom: -4%;
    font-size: 11.5vw;
  }


  .topContainer {
    margin-top: 8%;
    height: 25vh;
  }
  
  .middleSection {
    height: 32vh;
  
  }

  .description {
    font-family: 'montreal';
    font-size: 2.5vw;
    font-size: 2.5vh;
    color: gray;
    width: 35%;
    margin-left: 63%;

  }

  .timeLocationInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 2%;
    height: 10%;
    padding-bottom: 2%;
    font-family: 'APK';
  }
  
  .timeInfo, .currentLocation {
    font-family: 'APK';
    font-size: 0.5vw;
    
  }
  
  .timeInfo h4, .currentLocation h4 {
    margin: 0;
    font-family: 'APK';
    font-size: 0.5vw;
    margin-top: 2px;
    color: #333;
  }
  
  .timeInfo {
    text-align: left;
  }
  
  .time-label {
    display: flex;
    font-family: 'APK';
    height: 5px;
    margin-bottom: 5%;
    width: 20%;
    padding-bottom: 5%;
    justify-content: flex-start;
    align-items: center;
  }
  
  .loc-label {
  
    display: flex;
    font-family: 'APK';
    height: 5px;
    margin-bottom: 5%;
    width: 45%;
    padding-bottom: 5%;
    justify-content: flex-start;
    align-items: center;
  }

  .nameInfo li {
    font-family: 'APK';
    list-style-type: square;
    height: 20px;
    font-size: 1.4vw;
    margin-left: 12%;
    font-size: 1.5vh;
    
  
  }
}






 
